import React from "react";

import SliderBanner from "./sliderBanner/SliderBanner";
import Content from "./content/Content"
const Home = () => {
  return ( 
  <>
  <SliderBanner />
<Content/>
  </>
  )
};

export default Home;