import * as React from "react"
import Layout from "../components/layout/Layout"

import Home from "../components/home/Home"
// markup
const IndexPage = () => {
  return (
<Layout>
  <Home/>
</Layout>
  )
}

export default IndexPage
